<template>
  <div ref="wrapper">
    <div
      v-if="!field.singleEntry || field.label"
      class="row align-items-center justify-content-between"
    >
      <div v-if="!isModal" class="col">
        <label class="ma-0">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
      <div class="col-4">
        <b-form-checkbox v-model="isOutputField" switch>
          {{ $t("general.directInput") }}
        </b-form-checkbox>
      </div>
    </div>
    <div v-if="!isOutputField">
      <div class="d-flex flex-nowrap">
        <div style="flex-grow: 1; max-width: 100%">
          <div>
            <div
              v-for="(f, i) in value"
              :key="i"
              class="row align-items-center mt-1"
            >
              <div
                v-for="(val, key) in field.fields"
                :key="key"
                class="col input-group py-3 pb-5"
              >
                <v-select
                  v-if="val.type === 'select'"
                  v-model="value[i][val.name]"
                  class="form-control"
                  :items="val.options"
                  item-text="value"
                  item-value="value"
                  :disabled="isDisabled"
                />
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-if="val.type !== 'select'"
                        v-model="value[i][val.name]"
                        :label="$te(field.label + 'Values.' + val.name) ? $t(field.label + 'Values.' + val.name) : val.name"
                        :placeholder="$te(field.label + 'Values.' + val.name) ? $t(field.label + 'Values.' + val.name) : val.name"
                        :disabled="isDisabled"
                        @click="showMenu(true, i, val.name)"
                        @blur="checkRequiredFields"
                        outlined
                        dense
                        :append-outer-icon="!field.singleEntry && key == field.fields.length - 1 && !field.fixed ? 'fal fa-trash icon-lg' : ''"
                        @click:append-outer="removeValue(i)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <span v-if="field.hint" class="form-text text-muted">
            {{ field.hint }}
          </span>
          <span v-if="configValue" class="form-text text-muted pl-1">
            {{ configValueSyntax(configValue) }}
          </span>
          <span v-else-if="debugValue" class="form-text text-muted pl-1">
            {{ debugValue.syntax }}
          </span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="input-group py-3">
        <input
          v-model="directInputValue"
          :type="inputType"
          class="form-control"
          :class="validationState"
          :placeholder="field.placeholder ? field.placeholder : ''"
          :disabled="isDisabled"
          @click="showMenu(true, 0, value)"
          @input="resetConfigDebugValue"
          @blur="checkRequiredFields"
          @keydown.esc="showConfigValues = false"
          @keydown.tab="showConfigValues = false"
        />
      </div>
    </div>
    <div
      v-if="
        !field.fixed &&
        !isOutputField &&
        (field.singleEntry === undefined || !field.singleEntry)
      "
      class="col py-3 pl-1"
    >
      <a style="cursor: pointer; color: #b5b5c3 !important" @click="addValue">
        {{ $t("general.addField") }}
      </a>
    </div>
    <v-menu
      v-model="showConfigValues"
      content-class="bg-white"
      absolute
      offset-y
      :min-width="width"
      :max-width="width"
      :position-x="x"
      :position-y="y"
      max-height="300"
      @focus="showMenu()"
      @blur="showMenu(false)"
    >
      <v-list-item-group
        v-if="configValuesFiltered && configValuesFiltered.length > 0"
        v-model="selectedConfigValue"
        color="primary"
      >
        <v-subheader>Config Values</v-subheader>
        <v-list-item
          v-for="(item, index) in configValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setConfigValue(item)"
        >
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group
        v-if="outputValuesFiltered && outputValuesFiltered.length > 0"
        color="primary"
      >
        <v-subheader>Output Values</v-subheader>
        <v-list-item
          v-for="(item, index) in outputValuesFiltered"
          :key="index"
          @click="setOutputValue(item)"
        >
          <v-list-item-title>{{ item.value }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group
        v-if="
          debugValuesAllowed &&
          debugValuesFiltered &&
          debugValuesFiltered.length > 0
        "
        color="primary"
      >
        <v-subheader>Debug Values</v-subheader>
        <v-list-item
          v-for="(item, index) in debugValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setDebugValue(item)"
        >
          <v-list-item-title>{{ item.syntax }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-menu>
  </div>
</template>

<script>
import { bus } from "@/main";
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export default {
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      directInputValue: "",
      configValue: null,
      valueIndex: null,
      debugValue: null,
      showConfigValues: false,
      isOutputField: false,
      x: 0,
      y: 0,
      width: 100,
      selectedConfigValue: undefined,
      jsonData: "",
      cancelAction: false
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    inputType: function () {
      if (
        this.field.type === "int" &&
        this.value.toString().includes("{{", "}}")
      ) {
        return "text";
      }
      if (this.field.type === "string") {
        return "text";
      }
      if (this.showConfigValues) {
        return "text";
      }
      if (this.field.type === "typeCast") {
        return "text";
      }
      if (this.isOutputField) {
        return "text";
      }
      return "number";
    },
    validationState: function () {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (!this.value) {
        return "is-invalid";
      }
      return "is-valid";
    },
    configValuesFlat: function () {
      let configValues = [];
      this.configValues.forEach(configValue => {
        if (configValue.type === "json") {
          Object.entries(configValue.value).forEach(value => {
            configValues.push({
              label: `${configValue.label}.${value[0]}`,
              name: `${configValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          configValues.push(configValue);
        }
      });
      return configValues;
    },
    configValuesFiltered: function () {
      if (
        this.field.configValuesDisabled !== undefined &&
        this.field.configValuesDisabled
      ) {
        return [];
      }

      let values =
        this.configValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.value || typeof this.value !== "string") return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.value.toString().toLowerCase()) ||
          this.configValueSyntax(item).includes(this.value)
        );
      });
    },
    outputValuesFiltered: function () {
      if (
        this.field.outputValuesDisabled !== undefined &&
        this.field.outputValuesDisabled
      ) {
        return [];
      }
      if (!this.value || typeof this.value !== "string") {
        return this.outputValues;
      }
      return this.outputValues.filter(item => {
        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          this.outputValueSyntax(item).includes(this.value)
        );
      });
    },
    debugValuesFiltered: function () {
      if (!this.value || typeof this.value !== "string") {
        return this.debugValues;
      }

      return this.debugValues.filter(item => {
        if (typeof item.value !== "string") return true;

        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          item.syntax.includes(this.value)
        );
      });
    },
    debugValuesAllowed: function () {
      return (
        Object.keys(this.field).includes("debugValues") &&
        this.field.debugValues
      );
    },
    type: function () {
      return "";
    },
    selectOptions: function () {
      if (!this.field.options) {
        return [];
      }

      let options = this.field.options.map(o => {
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        let label = o.value;
        if (o.label) {
          label = o.label;
        } else if (this.$te(`${this.field.option}Options.${o.value}`)) {
          label = this.$t(`${this.field.option}Options.${o.value}`);
        }

        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });

      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      optionsSorted.forEach(option => {
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        newOptions.push(option);
      });
      return newOptions;
    },
    prismEditorClass: function () {
      const style = this.field.style ?? "dark";
      let c = "code-editor";
      if (style !== "light") {
        c = "editor " + c;
      }
      return c;
    },
    selectNodeElementIcon() {
      if (!this.elementSelectorNodeFound) {
        return null;
      }
      const workflowElementId =
        this.elementSelectorNodeFound.attrs.data.workflow_element_id;
      const originalElement = this.$root.$refs.WFDEditor.library.find(
        x => x.id === workflowElementId
      );
      const icon =
        "data:" +
        originalElement.icon.type +
        ";base64," +
        originalElement.icon.assetData.base64;
      return icon;
    },
    getTypeCastValueType() {
      if (typeof this.value !== "boolean" && Number(this.value)) {
        return "number";
      } else if (
        this.value === "true" ||
        this.value === "false" ||
        typeof this.value === "boolean"
      ) {
        return "bool";
      }
      return "string";
    }
  },
  watch: {
    isOutputField: function () {
      if (this.field.fixed) {
        return;
      }
      if (this.isOutputField) {
        this.value = "";
      } else {
        this.value = [];
        // this.value.push({name: "", value: this.directInputValue});
      }
    },
    directInputValue: function () {
      this.value = this.directInputValue;
    },
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      if (this.configValue) {
        newValue = this.configValueSyntax(this.configValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);

      if (this.field.name === "error_handling") {
        bus.$emit("show-hide-critical-path");
      } else if (this.field.name === "functions") {
        if (this.field.options === undefined || this.field.options === null) {
          return;
        }
        const option = this.field.options.find(o => o.value === this.value);
        if (option && option.types) {
          this.node.attrs.data.input[0].value = JSON.stringify(
            option.types,
            null,
            1
          );
        }
      }

      if (this.field.type === "time") {
        this.checkRequiredFields();
      }
    }
  },
  mounted() {
    if (this.field.type === "json") {
      if (typeof this.field.value === "string") {
        this.isOutputField = true;
        // this.value = this.field.value;
        this.directInputValue = this.field.value;
      }
      if (
        this.field.singleEntry &&
        (this.field.value === undefined || this.field.value.length === 0)
      ) {
        this.value = [];
        this.addValue();
      } else if (this.field.singleEntry) {
        this.value =
          this.field.value.length === undefined
            ? [this.field.value]
            : this.field.value;
      } else {
        this.value = this.field.value ?? [];
      }
      return;
    }
    this.value = this.field.value ?? "";

    if (typeof this.value === "string" && this.value.startsWith("{{config")) {
      const configValue = this.configValuesFlat.find(
        cf => cf.name === this.value.replace("{{config.", "").replace("}}", "")
      );

      if (configValue) {
        this.setConfigValue(configValue);
      }
    } else if (
      typeof this.value === "string" &&
      this.value.startsWith("{{output")
    ) {
      const outputValue = this.outputValues.find(
        dv => dv.value === this.value.replace("{{output.", "").replace("}}", "")
      );
      if (outputValue) {
        this.setOutputValue(outputValue);
      }
    }

    bus.$on("fireActionFinished", this.onFireActionFinished);
  },
  destroyed() {
    bus.$off("fireActionFinished", this.onFireActionFinished);
  },
  methods: {
    onFireActionFinished() {
      this.cancelAction = false;
    },
    checkType(item) {
      if (this.type === "number") {
        return !isNaN(item.value);
      }

      return true;
      //this.type === "text" || this.field.type === "time";
    },
    onEditorChange(value) {
      this.field.value = value.html;
    },
    showMenu(state = true, i = null, key = null) {
      if (this.field.type !== "json") {
        return;
      }

      if (i !== null && key !== null) {
        this.valueIndex = {
          i: i,
          key: key
        };
      }

      if (!state && this.field.type === "int" && isNaN(this.value)) {
        this.value = "";
      }
      let pos = this.$refs.wrapper?.getBoundingClientRect();
      this.x = pos.x;
      this.y = pos.y + pos.height;
      this.width = pos.width;
      this.showConfigValues = state;
      this.selectedConfigValue = undefined;
    },
    configValueSyntax(configValue) {
      return `{{config.${configValue.name}}}`;
    },
    setConfigValue(configValue) {
      this.configValue = configValue;
      if (this.isOutputField) {
        this.directInputValue = this.configValueSyntax(configValue);
        return;
      }
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.configValueSyntax(configValue);
        return;
      }
      this.value =
        typeof configValue.value === "object"
          ? JSON.stringify(configValue.value)
          : configValue.value;
      this.debugValue = null;
    },
    outputValueSyntax(outputValue) {
      return `{{output.${outputValue.value}}}`;
    },
    setOutputValue(outputValue) {
      this.configValue = null;
      this.debugValue = null;
      this.$set(this.field, "jsonData", outputValue.data);
      if (this.isOutputField) {
        this.directInputValue = this.outputValueSyntax(outputValue);
        return;
      }
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.outputValueSyntax(outputValue);
        return;
      }
      this.value = this.outputValueSyntax(outputValue);
    },
    setDebugValue(debugValue) {
      this.debugValue = debugValue;
      this.$set(this.field, "jsonData", JSON.stringify(debugValue.value));
      if (typeof debugValue.value !== "string") {
        this.value = debugValue.syntax;
        return;
      }

      this.value = debugValue.value;
    },
    selectChange(value) {
      this.value = value;
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }

      setTimeout(this.checkRequiredFields, 100);
    },
    addValue() {
      let element = {};
      this.field.fields.forEach(field => {
        this.$set(element, field.name, "");
      });
      this.value.push(element);
    },
    removeValue(index) {
      this.value.splice(index, 1);
    },
    deleteValue() {
      this.resetConfigDebugValue();
      this.value = "";
      this.field.value = "";
      this.field.jsonData = null;
    },
    resetConfigDebugValue() {
      this.configValue = null;
      this.debugValue = null;
    },
    addConfigValue() {
      this.$refs["addConfigValueModal"].show();
    },
    configValueSaved(configValue) {
      this.setConfigValue(configValue);
      this.$refs["addConfigValueModal"].hide();
      bus.$emit("update-config-values");
    },
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    },
    fireAction(field) {
      this.cancelAction = !this.cancelAction;
      bus.$emit("fireAction", field, !this.cancelAction);
    },
    openFileManager() {
      bus.$emit("openWFDFileManager", { nodeId: this.node.attrs.id });
    },
    addConditionValue(condition) {
      condition.values.push({
        type: "condition",
        connection_operator: "and",
        left: "",
        operator: this.operators[0].name,
        right: ""
      });
    },
    removeConditionValue(condition, value) {
      if (
        value.type === "condition" &&
        condition.values.filter(v => v.type === "condition").length <= 1
      )
        return;
      let index = condition.values.indexOf(value);
      if (index < 0) return;
      condition.values.splice(index, 1);
    },
    removeCondition(condition) {
      let configuration = this.node.attrs.data.configuration;
      let conditionsConfig = configuration.find(el => el.name === "conditions");
      let conditionsKey = configuration.indexOf(conditionsConfig);
      if (configuration[conditionsKey].value.length <= 2) return;
      let index = configuration[conditionsKey].value.indexOf(condition);
      if (index < 0) return;
      let args = {
        node: this.node,
        condition: condition
      };
      bus.$emit("remove-condition", args);
      this.reRenderList();
    },
    showConnectionOperator(condition, value, index) {
      return (
        condition.values[index - 1] &&
        condition.values[index - 1]?.type !== "bracketOpen" &&
        value.type !== "bracketClose"
      );
    },
    reRenderList() {
      this.showConditions = false;
      this.showConditions = true;
    },
    conditionToString(condition) {
      let string = "";
      condition.values.forEach((c, index) => {
        string += " ";
        if (this.showConnectionOperator(condition, c, index)) {
          let connectionOperator = this.connectionOperators.find(
            co => co.value === c.connection_operator
          ).text;
          string += connectionOperator + " ";
        }
        if (c.type === "condition") {
          let operator = this.operators.find(o => o.name === c.operator);
          string += `${c.left ?? ""} ${operator?.operator ?? ""} ${
            c.right ?? ""
          }`;
        } else if (c.type === "bracketOpen") {
          string += "(";
        } else if (c.type === "bracketClose") {
          string += ")";
        }
      });
      return string;
    },
    operatorSelected(value, operator) {
      if (this.disableSecondField.includes(operator)) {
        value.right = "";
      }
    },
    setValue(object, key, value) {
      this.$set(object, key, value);
      this.reRenderList();
    },
    copyText(text) {
      copyToClipboard(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    showAddToken() {
      this.$refs["addTokenModal"].show();
    },
    tokenSaved(token) {
      this.$refs["addTokenModal"].hide();
      this.field.options.push({
        value: token.id,
        label: token.label
      });
      this.value = token.id;
    }
  }
};
</script>

<style lang="scss">
.modal-body.add-config-value {
  padding: 0;
}

.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}

.v-input--selection-controls__input .v-icon {
  color: #e4ebef;
}
</style>
